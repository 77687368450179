import { getCartCountFromCookie } from '../session'
import { addPromoBannerEffects } from './promoBanner'
import { getRewardsIcon } from './rewards'
import {
  setDisplayFlex,
  setWidth,
  setDisplayInlineBlock,
  setWhiteSpace,
  setTextAlignLeft,
  setTextAlignCenter,
  setAlignItemsCenter,
  setPadding,
  removeLeftPadding,
  removeRightPadding,
} from './styles'
import {
  desktopSignInHTML,
  desktopMyAccountHTML,
  mobileSignInHTML,
  mobileMyAccountHTML,
} from './htmlSnippets'

const createMessageContainer = (width) => {
  const messageContainer = document.createElement('div')
  setWidth(messageContainer, width)
  setWhiteSpace(messageContainer, 'normal')
  setTextAlignLeft(messageContainer)
  setDisplayFlex(messageContainer)
  setAlignItemsCenter(messageContainer)
  return messageContainer
}

const addMessageToContainer = (message, container) => {
  const messageElement = document.createElement('span')
  setWidth(messageElement, '100vw')
  setPadding(messageElement, '0px 10px')
  setDisplayInlineBlock(messageElement)
  setTextAlignCenter(messageElement)
  messageElement.insertAdjacentHTML('afterbegin', message)
  container.appendChild(messageElement)
}

const stylePromoBanner = (container, { backgroundColor, textColor }) => {
  setWidth(container, `100%`)
  removeLeftPadding(container)
  removeRightPadding(container)
  container.style.backgroundColor = backgroundColor
  container.style.color = textColor
  container.innerHTML = ''
}

export const insertBannerHTML = ({
  backgroundColor = '#000000',
  textColor = '#FFFFFF',
  messages = [],
}) => {
  const bannerContainer = document.querySelector('.promo-banner')
  if (bannerContainer) {
    const messageContainer = createMessageContainer(
      `${messages.length * 100}vw`
    )
    stylePromoBanner(bannerContainer, { backgroundColor, textColor })
    for (const msg of messages) {
      addMessageToContainer(msg.message, messageContainer)
    }
    bannerContainer.appendChild(messageContainer)
    addPromoBannerEffects(messageContainer, messages.length)
  }
}

export const insertTbybHTML = (data) => {
  const desktopNav = document.querySelector('.navigation__desktop')
  if (data && desktopNav) {
    desktopNav.insertAdjacentHTML('beforeend', data)

    const desktopParentComponents = document.querySelectorAll(
      '.button-link__desktop-callout'
    )
    desktopParentComponents.forEach(function (component) {
      component.style.display = 'flex'
    })
  }
}

export const insertDesktopFeaturedContent = (desktopData) => {
  const desktopParentComponents = document.querySelectorAll(
    '.button-link__desktop-callout'
  )
  if (
    desktopData &&
    desktopParentComponents &&
    desktopParentComponents.length > 0
  ) {
    desktopParentComponents.forEach(function (component) {
      component.outerHTML = desktopData
    })
    const currentCalloutButtons = document.querySelectorAll('.tbyb-button')

    // Avoid open menu click
    if (currentCalloutButtons) {
      currentCalloutButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
          e.stopPropagation()
        })
      })
    }
  }
}

export const insertMobileFeaturedContent = (mobileData) => {
  const mobileParentComponent = document.querySelector(
    '.nav-link__mobile-callout'
  )
  if (mobileData && mobileParentComponent) {
    mobileParentComponent.style.gridTemplateColumns = '100%'
    mobileParentComponent.innerHTML = mobileData

    // Avoid open menu click
    const link = mobileParentComponent.querySelector('a')
    link &&
      link.addEventListener('click', function (e) {
        e.stopPropagation()
      })
  }
}

const getAccountElements = () => ({
  menu: document.querySelector('#menu'),
  searchContainer: document.querySelector('#searchContainer'),
  bottomRowAccount: document.querySelector(
    '.bottom-row__links-right__my-account'
  ),
})

export const getInsertedAccountElements = () => ({
  desktopMyAccount: document.querySelector('#nav-link__desktop--my-account'),
  menuLevels: document.querySelectorAll('.menu__container__top'),
  mobileMyAccountIcon: document.querySelector(
    '.nav-link__mobile--account > svg'
  ),
  mobileMyAccount: document.querySelector('#nav-link__mobile--sign-in'),
})

const removeMyAccountLoggedInClickEvent = (element) => {
  element.removeAttribute('href')
}

export const insertMyAccountHTML = (mobileSessionData, desktopSessionData) => {
  const { menu, searchContainer, bottomRowAccount } = getAccountElements()

  bottomRowAccount.classList.add('my-account--logged-in')
  removeMyAccountLoggedInClickEvent(bottomRowAccount)
  searchContainer &&
    searchContainer.insertAdjacentHTML('afterend', desktopMyAccountHTML)
  menu && menu.insertAdjacentHTML('afterbegin', mobileMyAccountHTML)

  const { desktopMyAccount, mobileMyAccount, mobileMyAccountIcon } =
    getInsertedAccountElements()

  mobileMyAccount &&
    mobileMyAccount.insertAdjacentHTML('afterend', mobileSessionData)

  mobileMyAccountIcon.classList.add('nav-link__mobile__icon--logged-in')

  const myAccountMenu = mobileMyAccount.nextElementSibling
  myAccountMenu.classList.add('my-account-menu')
  myAccountMenu.style.top = '100px'

  desktopMyAccount &&
    desktopMyAccount.insertAdjacentHTML('afterend', desktopSessionData)

  const mobileMyAccountMenu = mobileMyAccount.nextElementSibling
  mobileMyAccountMenu.classList.add('my-account-menu')
  mobileMyAccountMenu.style.top = '100px'
}

export const insertSignInHTML = () => {
  const { menu, searchContainer } = getAccountElements()

  searchContainer &&
    searchContainer.insertAdjacentHTML('afterend', desktopSignInHTML)
  menu && menu.insertAdjacentHTML('afterbegin', mobileSignInHTML)
}

const hidePossibleLink = (id) => {
  const menuLink = document.querySelector(`.nav-link__${id}-link`)
  if (menuLink) {
    menuLink.style.display = 'none'
  }
}

const makeSureMenuIsVisible = (id) => {
  const menuDiv = document.querySelector(`.nav-link__${id}`)
  if (menuDiv.style.display === 'none') {
    menuDiv.style.display = ''
  }
}

export const insertNavLinkHTML = (data) => {
  for (const [id, html] of Object.entries(data)) {
    if (!html) continue

    const dropdownDiv = document.querySelector(`#link-${id}`)
    const menuDiv = document.querySelector(`.nav-link__${id}`)

    if (dropdownDiv) {
      dropdownDiv.insertAdjacentHTML('beforeend', html)
    }

    if (menuDiv) {
      hidePossibleLink(id)
      makeSureMenuIsVisible(id)
      menuDiv.insertAdjacentHTML('afterend', html)
    }
  }
}

export const insertCartItemCount = () => {
  const desktopCartCountContainerEl = document.querySelector(
    '.nav-link__desktop__cart-count-container'
  )
  const mobileCartCountContainerEl = document.querySelector(
    '.nav-link__mobile__cart-count-container'
  )
  const desktopCartCountEl = document.querySelector(
    '.nav-link__desktop__cart-count'
  )
  const mobileCartCountEl = document.querySelector(
    '.nav-link__mobile__cart-count'
  )

  const cartCount = getCartCountFromCookie()

  if (cartCount && cartCount > 0) {
    desktopCartCountEl.innerHTML = cartCount
    mobileCartCountEl.innerHTML = cartCount
    setDisplayFlex(desktopCartCountContainerEl)
    setDisplayFlex(mobileCartCountContainerEl)
  }
}

export const insertFooterHTML = (data) => {
  for (const [id, html] of Object.entries(data)) {
    if (html) {
      document.querySelector(`#${id}`).innerHTML = html
    }
  }
}

export const checkIfFooterIsReady = (data) => {
  if (document.readyState === 'loading') {
    document.addEventListener(
      'DOMContentLoaded',
      function () {
        insertFooterHTML(data)
      },
      false
    )
  } else {
    insertFooterHTML(data)
  }
}

export const enableNewsletterInput = async () => {
  await waitForElementToExist('[data-dia-email-signup="1"]', '.k-footer')

  const newsletterForms =
    document.querySelectorAll('[data-dia-email-signup="1"]') || []
  newsletterForms.forEach((form) => {
    if (form.querySelector('input')) {
      form.querySelector('input').disabled = false
    }
    if (form.querySelector('[type=submit]')) {
      form.querySelector('[type=submit]').disabled = false
    }
  })
}

const waitForElementToExist = (
  selector,
  selectorToObserve,
  timeToWait = 10000
) => {
  if (!selector) return Promise.reject(null)

  return new Promise((resolve) => {
    // Define for how long will wait the element until give up
    setTimeout(() => {
      resolve(null)
    }, timeToWait)

    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    const willObserve =
      document.querySelector(selectorToObserve) || document.body
    observer.observe(willObserve, { childList: true, subtree: true })
  })
}

export const insertMobileDiscountLine = async (discountLine) => {
  const discountLineElement = await waitForElementToExist(
    '#mobile-discount-line',
    '.mobile-navigation-menu'
  )
  if (discountLineElement && discountLine) {
    discountLineElement.outerHTML = discountLine
  }
}

const hideMobileDiscountLine = () => {
  const discountLineElement = document.querySelector('#mobile-discount-line')
  if (discountLineElement) {
    discountLineElement.style.display = 'none'
  }
}

const insertMobileMyAccountRewards = async (rewardsData) => {
  const {
    first_name: name,
    tier_name: tier,
    points_balance: points,
    reward_cents: reward,
  } = rewardsData

  const myAccountNameElement = await waitForElementToExist(
    '#mobile-myaccount-name'
  )
  if (myAccountNameElement && name) {
    myAccountNameElement.innerHTML = `Hi, ${name}`
  }

  const mobileRewardsElement = await waitForElementToExist(
    '#mobile-rewards-points'
  )
  if (mobileRewardsElement && tier) {
    hideMobileDiscountLine()
    const rewardText = reward
      ? `<span class="k-text--bold">$${reward / 100}</span>&nbsp;Reward`
      : `<span class="k-text--bold">${points}</span>&nbsp;Points`
    mobileRewardsElement.innerHTML = `${tier}<img alt="${tier}" src="${getRewardsIcon(
      tier
    )}" width="16px" height="13px" />${rewardText}`
  }
}

const insertDesktopMyAccountRewards = async (rewardsData) => {
  const {
    first_name: name,
    tier_name: tier,
    points_balance: points,
    reward_cents: reward,
  } = rewardsData

  const desktopMyAccountNameElement = await waitForElementToExist(
    '#desktop-myaccount-name'
  )
  if (desktopMyAccountNameElement && name) {
    desktopMyAccountNameElement.innerHTML = `Hi, ${name}`
  }

  const desktopRewardsElement = await waitForElementToExist(
    '#desktop-rewards-points'
  )
  if (desktopRewardsElement && tier) {
    const rewardText = reward
      ? `<span class="k-text--bold">$${reward / 100}</span>&nbsp;Reward`
      : `<span class="k-text--bold">${points}</span>&nbsp;Points`
    desktopRewardsElement.innerHTML = `${tier}<img alt="${tier}" src="${getRewardsIcon(
      tier
    )}" width="16px" height="13px" />${rewardText}`
  }
}

export const insertRewardsHTML = (rewardsData) => {
  if (!rewardsData) return

  insertMobileMyAccountRewards(rewardsData)
  insertDesktopMyAccountRewards(rewardsData)
}

export const setLogoImage = () => {
  const logoLinks = [
    document.querySelector('#main-logo'),
    document.querySelector('#main-logo-small-desktop'),
    document.querySelector('#main-logo-mobile'),
  ]
  logoLinks.forEach(function (logoLink) {
    if (logoLink) {
      const diaLogo = logoLink.querySelector('.dia-logo')
      const elevenHLogo = logoLink.querySelector('.elevenH-logo')
      if (
        window.location.pathname.indexOf('11honore') > -1 &&
        elevenHLogo.style.display === 'none'
      ) {
        diaLogo.style.display = 'none'
        elevenHLogo.style.display = 'inline-block'
      }
      if (
        window.location.pathname.indexOf('11honore') === -1 &&
        diaLogo.style.display === 'none'
      ) {
        elevenHLogo.style.display = 'none'
        diaLogo.style.display = 'inline-block'
      }
    }
  })
}
