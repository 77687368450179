import { getEnvVar } from '../env'

export const desktopSignInHTML =
  `<a href="${getEnvVar(
    'signInUrl'
  )}" id="nav-link__desktop--sign-in" class="top-row__links--sign-in k-mx-3">\n` +
  '    <svg\n' +
  '            xmlns="http://www.w3.org/2000/svg"\n' +
  '            width="24"\n' +
  '            height="24"\n' +
  '            viewBox="0 0 24 24"\n' +
  '            fill="none"\n' +
  '            stroke="#262626"\n' +
  '            stroke-width="2"\n' +
  '            stroke-linecap="round"\n' +
  '            stroke-linejoin="round"\n' +
  '            class="feather feather-user"\n' +
  '    >\n' +
  '        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>\n' +
  '        <circle cx="12" cy="7" r="4"></circle>\n' +
  '    </svg>\n' +
  '    <div class="k-text--sm k-text--bold k-px-2">Sign In</div>\n' +
  '</a>\n'

export const desktopMyAccountHTML =
  '<a id="nav-link__desktop--my-account" class="top-row__links--my-account my-account k-mx-3">\n' +
  '    <svg\n' +
  '            xmlns="http://www.w3.org/2000/svg"\n' +
  '            width="34"\n' +
  '            height="34"\n' +
  '            viewBox="0 -1 24 28"\n' +
  '            fill="none"\n' +
  '            stroke="#262626"\n' +
  '            stroke-width="2"\n' +
  '            stroke-linecap="round"\n' +
  '            stroke-linejoin="round"\n' +
  '            class="feather feather-user"\n' +
  '    >\n' +
  '        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>\n' +
  '        <circle cx="12" cy="7" r="4"></circle>\n' +
  '    </svg>\n' +
  '    <div class="k-text--sm k-px-2 k-mx-1">\n' +
  '        <div id="desktop-myaccount-name" class="k-text--bold">My Account</div>\n' +
  '        <div id="desktop-rewards-points" class="k-text--xxs k-flex k-flex-nowrap k-items-center"></div>\n' +
  '    </div>\n' +
  '</a>'

export const mobileSignInHTML =
  '<div class="menu__header k-px-4 k-py-3">\n' +
  '  <div class="menu__back__container">\n' +
  '    <p class="menu__back k-text--bold"></p>\n' +
  '  </div>\n' +
  '  <div id="nav-link__mobile--sign-in" class="menu__sign-in">\n' +
  '    <div>\n' +
  '      <svg\n' +
  '        class="k-mt-2"\n' +
  '        xmlns="http://www.w3.org/2000/svg"\n' +
  '        width="20"\n' +
  '        height="20"\n' +
  '        viewBox="0 0 24 24"\n' +
  '        fill="none"\n' +
  '        stroke="#212121"\n' +
  '        stroke-width="2"\n' +
  '        stroke-linecap="round"\n' +
  '        stroke-linejoin="round"\n' +
  '        className="feather feather-user"\n' +
  '      >\n' +
  '        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>\n' +
  '        <circle cx="12" cy="7" r="4"></circle>\n' +
  '      </svg>\n' +
  '      <div class="k-px-3">\n' +
  `        <a href="${getEnvVar(
    'signInUrl'
  )}" class="menu__sign-in__link k-text--bold k-mb-1 k-text--sm">Sign In or Join</a>\n` +
  '        <div id="mobile-discount-line" class="k-text--xxxs">Join to get 25% off your first purchase.</div>\n' +
  '      </div>\n' +
  '    </div>\n' +
  '  </div>\n' +
  '</div>\n'

export const mobileMyAccountHTML =
  '<div class="menu__header k-px-4 k-py-3">\n' +
  '  <div class="menu__back__container">\n' +
  '    <p class="menu__back k-text--bold"></p>\n' +
  '  </div>\n' +
  '  <div id="nav-link__mobile--sign-in" class="menu__sign-in menu__container__top">\n' +
  '    <div>\n' +
  '      <svg\n' +
  '        xmlns="http://www.w3.org/2000/svg"\n' +
  '        width="34"\n' +
  '        height="34"\n' +
  '        viewBox="0 -5 24 38"\n' +
  '        fill="none"\n' +
  '        stroke="#212121"\n' +
  '        stroke-width="2"\n' +
  '        stroke-linecap="round"\n' +
  '        stroke-linejoin="round"\n' +
  '        className="feather feather-user"\n' +
  '        class="menu__my-account-icon"\n' +
  '      >\n' +
  '        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>\n' +
  '        <circle cx="12" cy="7" r="4"></circle>\n' +
  '      </svg>\n' +
  '      <div class="k-px-3">\n' +
  '        <a class="k-text--sm k-text--bold k-mb-1" href="#" id="mobile-myaccount-name">My Account</a>\n' +
  '        <div id="mobile-discount-line" class="k-text--xxxs">Apply 250 points for $5 off at checkout</div>\n' +
  '        <div id="mobile-rewards-points" class="k-text--xxs k-flex k-flex-nowrap k-items-center k-mt-n-1"></div>\n' +
  '      </div>\n' +
  '    </div>\n' +
  '    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '      <path d="M5.25 11.6668L9.91667 7.00016L5.25 2.3335" stroke="#767676" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />\n' +
  '    </svg>\n' +
  '  </div>\n' +
  '</div>\n'

export const mobileBackContainerHTML = (title) =>
  '<svg style="position: absolute; left: 24px;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M8.75 11.6668L4.08333 7.00016L8.75 2.3335" stroke="#767676" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
  '</svg>' +
  `<p class="menu__back k-text--bold k-text--sm">${title}</p>`

export const mobileChevronHTML = (className) =>
  `<svg className=${className} width="14" height="14" viewBox="0 0 14 14" fill="none"\n` +
  'xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M5.25 11.6668L9.91667 7.00016L5.25 2.3335" stroke="#767676" stroke-width="2" stroke-linecap="round"\n' +
  'stroke-linejoin="round"/>\n' +
  '</svg>'
